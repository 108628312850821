@import 'modal-video.css';


body {
  /* font-family: 'Work Sans', sans-serif; */

}

.my-info-window {
  /* Twoje niestandardowe style */
  background: white;
  border: 1px solid black;
  padding: 10px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  /* inne style */
}

/* Arrows */
.slick-prev,
.slick-next 
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    bottom: 15px!important;

    display: block;

    width: 40px!important;
    height: 31px;
    


    cursor: pointer;

    color: #198754!important;
    border: none;
    outline: none;
    background: #198754;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    
    outline: none;
  
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    
    opacity: .75!important;
    color: white;
 
    padding:0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    right: 75px!important;
    background-image: url('../public/images/arrow-left.png');
    z-index: 60;
}

.slick-prev:before
{
    background-image: url('../public/images/arrow-left.png');
}


.slick-next
{
    right: 25px!important;
    background-image: url('../public/images/arrow-right.png');
}

.slick-next:before
{
    background-image: url('../public/images/arrow-right.png');
}



.table-more td{
    @apply px-3 py-2 border-b border-gray-200;
}




.st0 {
    opacity: 0.6;
    fill: #808080;
  }

  .st1 {
    opacity: 0.6;
    fill: #39b54a;
  }

  .st2 {
    opacity: 0.6;
    fill: #fcee21;
  }

  .st3 {
    opacity: 0.6;
    fill: #f15a24;
  }

  .polygon {
    opacity: 0;
  }

  .polygon:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  .st4 {
    fill: none;
  }

  .st5 {
    opacity: 0.72;
    fill: #fffdfd;
  }

  .st6 {
    font-family: "Work Sans", sans-serif;
  }

  .st7 {
    font-size: 18px;
  }

  #tooltip {
    position: absolute;
    display: none;
    background-color: white;
    border: none;
    padding: 15px;
  }

  /* Dodatkowy styl dla tabelki */
  #tooltip table {
    width: 100%;
  }

  #tooltip th {
    text-align: left;
  }
  html {
    scroll-behavior: smooth;
  }
  .section::before {
    content: "";
    display: block;
    height: 90px; /* Wysokość Twojego menu */
    margin-top: -90px; /* Negatywna wartość wysokości Twojego menu */
    visibility: hidden;
  }
  .bgGradient-green{
    background-image: url('../public/images/trawa.webp')!important;
    /* background: rgb(17,133,80)!important;
background: linear-gradient(150deg, rgba(17,133,80,1) 0%, rgba(65,175,114,1) 35%, rgba(17,133,80,1) 100%)!important; */
  }